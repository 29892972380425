import React from "react";
import { RadioGroup } from "@headlessui/react";
import { Controller } from "react-hook-form";
import classNames from "lib/classNames";
import useField from "lib/useField";

export default function Radios({ control, errors, field, variant }) {
  const { name, disabled, required, requiredMessage, options } =
    useField(field);
  const hasError = errors[name] || false;
  const title = `${field["#title"]} ${required ? "*" : ""}`;
  const themes = {
    default: {
      border: "border-default",
      check: "bg-default text-white",
    },
    primary: {
      border: "border-primary",
      check: "bg-primary text-white",
    },
    institution: {
      border: "border-institution",
      check: "bg-institution text-white",
    },
    formation: {
      border: "border-formation",
      decoration: "text-formation",
      check: "bg-formation text-default",
    },
    internship: {
      border: "border-internship",
      check: "bg-internship text-white",
    },
    news: {
      border: "border-news",
      check: "bg-news text-white",
    },
    inscription: {
      border: "border-inscription",
      check: "bg-inscription text-white",
    },
  };

  if (options === null) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && requiredMessage,
        disabled,
      }}
      render={({ field: { value, onChange } }) => (
        <RadioGroup value={value} onChange={onChange}>
          <RadioGroup.Label
            className={classNames(
              hasError && "text-red-500",
              "inline-block font-important font-bold mb-1"
            )}
          >
            {title}
          </RadioGroup.Label>

          {hasError && (
            <p className="text-sm text-red-500 mb-2 font-semibold">
              {errors[name].message}
            </p>
          )}

          <div className="flex flex-col gap-2">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.id}
                value={option.value}
                className="mt-1 cursor-pointer"
              >
                {({ checked }) => (
                  <div className={classNames("flex items-center gap-4")}>
                    <span
                      className={classNames(
                        "flex items-center justify-center p-2  border bg-gray-100 rounded-full transition-colors",
                        themes[variant].border
                      )}
                    >
                      <span
                        className={classNames(
                          "h-2 w-2 rounded-full transition-colors",
                          checked && themes[variant].check
                        )}
                      />
                      <span className="sr-only">
                        {checked ? "Coché" : "Non Coché"}
                      </span>
                    </span>
                    <span>{option.key}</span>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
    />
  );
}
