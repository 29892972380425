import React from "react";
import Link from "next/link";

export default function FooterMenu({ menu }) {
  return (
    menu && (
      <ul className="flex flex-col md:flex-row flex-wrap md:-mx-4 md:divide-x divide-white">
        {menu.map((item) => {
          return (
            <li key={item.id}>
              <Link href={item.url} passHref>
                <a className="block px-4">{item.title}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
