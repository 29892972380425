import React from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";

export default function BrandingContent({ home = false }) {
  const { t } = useTranslation("common");
  const TitleTag = home ? "h1" : "div";

  return (
    <div className="inlint-block w-[200px] md:w-[215px]">
      <Image
        src="/images/logo.png"
        width={215}
        height={134}
        layout="responsive"
        alt={t("branding.alt")}
      />
      <TitleTag className="sr-only">{t("branding.title")}</TitleTag>
    </div>
  );
}
