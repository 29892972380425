import React from "react";

export default function IconBannerPattern({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 665 222"
      className={className}
      aria-hidden="true"
    >
      <g transform="translate(2199 -2393)" fill="currentColor">
        <path d="M-1866 2504h110v111h-110z" />
        <path d="M-1756 2393h111v111h-111z" />
        <path d="M-1645 2504h111v111h-111z" />
        <path d="M-1977 2393h111v111h-111z" />
        <circle
          cx="15.5"
          cy="15.5"
          r="15.5"
          transform="translate(-1604 2434)"
        />
        <ellipse
          cx="16"
          cy="15.5"
          rx="16"
          ry="15.5"
          transform="translate(-1826 2434)"
        />
        <ellipse
          cx="15.5"
          cy="16"
          rx="15.5"
          ry="16"
          transform="translate(-1715 2550)"
        />
        <ellipse
          cx="15.5"
          cy="16"
          rx="15.5"
          ry="16"
          transform="translate(-1938 2550)"
        />
        <path d="M-2199 2393h111v111h-111z" />
        <path d="M-2088 2504h111v111h-111z" />
        <circle
          cx="15.5"
          cy="15.5"
          r="15.5"
          transform="translate(-2047 2434)"
        />
        <ellipse
          cx="15.5"
          cy="16"
          rx="15.5"
          ry="16"
          transform="translate(-2160 2550)"
        />
      </g>
    </svg>
  );
}
