import React from "react";
import parse, { domToReact, Element } from "html-react-parser";
import Image from "next/image";
import Link from "next/link";
import Button from "./Button";

export default function Body({ value, variant = "default" }) {
  return (
    <>
      {parse(value, {
        replace: (domNode) => {
          if (domNode instanceof Element && domNode.name === "img") {
            const { src, alt, width, height } = domNode.attribs;

            return (
              <Image
                src={`${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${src}`}
                width={`${width}px`}
                height={`${height}px`}
                alt={alt}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            );
          }

          if (domNode.name === "a") {
            const { href, class: className } = domNode.attribs;

            return (
              <Link href={href} prefetch={false} passHref legacyBehavior>
                {className && className.includes("button") ? (
                  <Button as="a" theme={variant} size="small">
                    {domToReact(domNode.children)}
                  </Button>
                ) : (
                  <a className={className}>{domToReact(domNode.children)}</a>
                )}
              </Link>
            );
          }

          return domNode;
        },
      })}
    </>
  );
}
