import { XIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";
import React, { Fragment, useEffect, useState } from "react";
import MainMenu from "components/Menu/MainMenu";
import MainMenuMobile from "components/Menu/MainMenuMobile";
import IconEnvelope from "components/Icons/IconEnvelope";
import IconSearch from "components/Icons/IconSearch";
import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";

const NAVBAR_BREAKPOINT = 1280;

export default function Navbar({ globals, scrollToFooter = () => null }) {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= NAVBAR_BREAKPOINT);
    };

    if (typeof window !== "undefined") {
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(false);
    }
  }, [isDesktop, setIsOpen]);

  return isDesktop ? (
    <div className="flex justify-end h-full">
      {globals?.menus?.main && <MainMenu menu={globals.menus.main} depth={2} />}
      <ul className="relative flex flex-col w-36 divide-y divide-default border-y border-default">
        <li className="flex-1">
          <Link href={t("form.contact.path")}>
            <a className="flex flex-col items-center justify-center h-full ">
              <IconEnvelope className="h-8 w-8" />
              <span className="text-sm">{t("form.contact.label")}</span>
            </a>
          </Link>
        </li>
        <li className="flex-1">
          <button
            type="button"
            className="flex flex-col items-center justify-center h-full w-full"
            onClick={scrollToFooter}
          >
            <IconSearch className="h-8 w-8" />
            <span className="text-sm">{t("search.title")}</span>
          </button>
        </li>
      </ul>
    </div>
  ) : (
    <div className="flex justify-end h-full">
      <ul className="flex flex-row gap-0 h-full">
        <li className="hidden sm:block relative h-full w-24 md:w-36 border-l border-y border-default">
          <Link href={t("form.contact.path")}>
            <a className="flex flex-col items-center justify-evenly p-2 h-full text-center text-sm">
              <IconEnvelope className="h-16 w-16" />
              <span className="sr-only md:not-sr-only text-sm">
                {t("form.contact.label")}
              </span>
            </a>
          </Link>
        </li>
        <li className="hidden sm:block relative h-full w-24 md:w-36 border-l border-y border-default">
          <button
            type="button"
            className="flex flex-col items-center justify-evenly p-2 w-full h-full text-center text-sm"
            onClick={scrollToFooter}
          >
            <IconSearch className="h-16 w-12" />
            <span className="sr-only md:not-sr-only text-sm">
              {t("search.title")}
            </span>
          </button>
        </li>
        <li className="relative h-full w-24 md:w-36 border border-default">
          <button
            type="button"
            className="flex flex-col items-center justify-evenly p-2 w-full h-full text-center text-sm"
            onClick={() => setIsOpen(true)}
          >
            <div
              className="flex flex-col justify-evenly h-16 w-12"
              aria-hidden="true"
            >
              <span className="block h-px bg-default" />
              <span className="block h-px bg-default" />
              <span className="block h-px bg-default" />
            </div>
            <span className="sr-only md:not-sr-only text-sm">Menu</span>
          </button>
        </li>
      </ul>

      <Transition as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black/70 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            className="absolute inset-y-0 right-0 left-0 bg-white h-full md:left-1/4"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative px-8 md:px-16 py-20 bg-primary bg-opacity-90 text-white h-full overflow-auto">
              {globals?.menus?.main && (
                <MainMenuMobile
                  menu={globals.menus.main}
                  close={() => setIsOpen(false)}
                />
              )}

              <div className="absolute top-0 right-0 mt-4 mr-4">
                <button
                  type="button"
                  className="flex flex-col items-center justify-center rounded-xl bg-primary p-2 md:p-4"
                  onClick={() => setIsOpen(false)}
                >
                  <XIcon className="h-8 w-8" />
                  <span className="sr-only md:not-sr-only text-sm">
                    {t("utils.close")}
                  </span>
                </button>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition>
    </div>
  );
}
