import React from "react";
import Link from "next/link";
import BrandingContent from "components/Branding/BrandingContent";

export default function Branding({ home = false, small = false }) {
  return home ? (
    <BrandingContent small={small} home />
  ) : (
    <Link href="/" passHref>
      <a className="inline-block no-underline">
        <BrandingContent small={small} />
      </a>
    </Link>
  );
}
