import React, { forwardRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = forwardRef(({ field }, ref) => {
  return (
    <div id={field["#webform_id"]}>
      <div className="prose prose-light text-base mb-8 italic">
        Ce site est prot&eacute;g&eacute; par reCaptcha et la{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          politique de confidentialit&eacute;
        </a>{" "}
        et les{" "}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          termes de service
        </a>{" "}
        de Google s&apos;appliquent.
      </div>
      <ReCAPTCHA
        ref={ref}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
      />
    </div>
  );
});

Captcha.displayName = "Captcha";
export default Captcha;
