import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";

export default function Textarea({ register, errors, field, variant }) {
  const { id, name, disabled, required, requiredMessage } = useField(field);
  const hasError = errors[name] || false;
  const title = `${field["#title"]} ${required && "*"}`;
  const themes = {
    default: "border-default",
    primary: "border-primary",
    institution: "border-institution",
    formation: "border-formation",
    internship: "border-internship",
    news: "border-news",
    inscription: "border-inscription",
  };

  return (
    <>
      <label htmlFor={id} className="sr-only">
        {title}
      </label>

      <textarea
        id={id}
        {...register(name, {
          required: required && requiredMessage,
          disabled,
        })}
        rows={field["#rows"] ? field["#rows"] : 5}
        placeholder={title}
        className={classNames(
          hasError ? "border-red-500" : themes[variant],
          "w-full border px-6 py-4 placeholder:text-default-light border-opacity-50 outline-none focus:border-opacity-100"
        )}
      />

      {hasError && (
        <p className="text-sm text-red-500 px-6 mt-2 font-semibold">
          {errors[name].message}
        </p>
      )}
    </>
  );
}
