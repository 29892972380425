import React, { useEffect, useState } from "react";
import Link from "next/link";
import classNames from "lib/classNames";
import { useRouter } from "next/router";

export default function MainMenu({ menu }) {
  return (
    <ul className="flex flex-row gap-0 h-full">
      {menu.map((item) => {
        return (
          <li
            key={item.id}
            className="relative h-full w-36 border-l last:border-r border-t border-b border-default"
          >
            <MainMenuItem item={item} />
          </li>
        );
      })}
    </ul>
  );
}

function MainMenuItem({ item }) {
  const { asPath } = useRouter();
  const themes = {
    institution: "bg-institution",
    formation: "bg-formation",
    internship: "bg-internship",
    pastorale: "bg-primary",
    news: "bg-news",
    inscription: "bg-inscription",
  };
  const theme = item.options?.attributes["data-icon-name"];
  const isActive =
    asPath === item.url ||
    (asPath.length > 1 && item.url.startsWith(`${asPath}/`));

  if (item.id === "standard.front_page") {
    return (
      <Link href="/" passHref>
        <a className="p-2 text-sm">
          <span>{item.title}</span>
        </a>
      </Link>
    );
  }

  return (
    <Link href={item.url} passHref>
      <a className="group relative flex flex-col items-center justify-evenly p-2 h-full text-center text-sm">
        <span>{item.title}</span>
        <span
          className={classNames(
            "absolute inset-x-0 h-4 transition-all",
            isActive ? "bottom-0" : "bottom-4 group-hover:bottom-0",
            theme ? themes[theme] : "bg-primary"
          )}
          aria-hidden="true"
        />
      </a>
    </Link>
  );
}
