import React, { forwardRef } from "react";
import classNames from "lib/classNames";

const Button = forwardRef(
  (
    {
      children,
      theme = "institution",
      as = "button",
      size = "default",
      shadow = false,
      ...props
    },
    ref
  ) => {
    const Tag = as;

    const themes = {
      default: "border-default bg-default text-white",
      primary: "border-primary bg-primary text-white",
      white: "border-white bg-white text-default-dark shadow",
      institution: "border-institution bg-institution text-white",
      formation: "border-formation bg-formation text-default-dark",
      internship: "border-internship bg-internship text-white",
      news: "border-news bg-news text-white",
      inscription: "border-inscription bg-inscription text-white",
    };

    const sizes = {
      default: "py-1.5 px-4 lg:py-4 lg:px-8",
      small: "py-1.5 px-4",
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          "inline-block border text-lg font-important font-bold uppercase no-underline tracking-wider transition",
          shadow && "shadow-lg",
          themes[theme],
          sizes[size]
        )}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
