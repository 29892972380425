import React from "react";

export default function IconFacebook({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.411 31.221"
      className={className}
      aria-hidden="true"
    >
      <path
        d="M31.411 15.705a15.706 15.706 0 1 0-18.148 15.516V20.243H9.249v-4.538h4.014v-3.46c0-3.936 2.332-6.119 5.919-6.119a22.213 22.213 0 0 1 3.5.33v3.839h-1.968a2.289 2.289 0 0 0-2.573 2.464v2.946h4.362l-.7 4.538h-3.664v10.978a15.709 15.709 0 0 0 13.262-15.516"
        fill="currentColor"
      />
    </svg>
  );
}
