import React, { useState } from "react";
import Link from "next/link";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import classNames from "lib/classNames";

export default function MainMenuMobile({ menu, close = () => null }) {
  const { asPath } = useRouter();

  return (
    <ul className="flex flex-col gap-4 md:gap-8">
      {menu.map((item) => {
        const hasDropdown = item.items?.length > 0;
        const isActive = asPath.includes(item.url);

        return (
          <li key={item.id}>
            {hasDropdown ? (
              <MainMenuDropdown item={item} isActive={isActive} close={close} />
            ) : (
              <Link
                href={item.id === "standard.front_page" ? "/" : item.url}
                passHref
              >
                <a
                  className="font-important font-bold text-2xl md:text-3xl"
                  onClick={() => close()}
                >
                  <span>{item.title}</span>
                </a>
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export function MainMenuDropdown({
  item,
  isActive = false,
  level = 0,
  close = () => null,
}) {
  const { asPath } = useRouter();
  const [open, setOpen] = useState(isActive);

  const themes = {
    0: {
      button: "text-2xl md:text-3xl",
      icon: "h-8 w-8",
      dropdown: "gap-3 pl-4 pt-4 md:pt-8",
    },
    1: {
      button: "text-xl",
      icon: "h-4 w-4",
      dropdown: "gap-1 py-2",
    },
    2: {
      button: "",
      icon: "h-4 w-4",
      dropdown: "gap-1 py-2",
    },
    3: {
      button: "",
      icon: "",
      dropdown: "",
    },
  };

  return (
    <Menu as="div">
      <Menu.Button
        className={classNames(
          "flex items-center gap-2 font-important font-bold",
          themes[level].button
        )}
        onClick={() => setOpen((o) => !o)}
      >
        <span>{item.title}</span>
        <ChevronDownIcon
          className={classNames(themes[level].icon, open && "rotate-180")}
        />
      </Menu.Button>
      {open && (
        <Menu.Items
          className={classNames("flex flex-col", themes[level].dropdown)}
          static
        >
          {item.items.map((subItem) => {
            const hasDropdown = subItem.items?.length > 0;
            const isActive = asPath.includes(subItem.url);

            return (
              <Menu.Item key={subItem.id}>
                {hasDropdown ? (
                  <MainMenuDropdown
                    item={subItem}
                    isActive={isActive}
                    level={level + 1}
                  />
                ) : (
                  <Link href={subItem.url || "#"} scroll={false} passHref>
                    <a
                      className={classNames(
                        "font-important font-bold",
                        themes[level + 1].button
                      )}
                      onClick={() => close()}
                    >
                      {subItem.title}
                    </a>
                  </Link>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      )}
    </Menu>
  );
}
