import React, { createRef, useEffect, useLayoutEffect, useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";

export default function VideoBlock({ data }) {
  const ref = createRef();
  const [show, setShow] = useState(true);

  return (
    <Transition
      show={show}
      enter="transition-all duration-500"
      enterFrom="h-0"
      enterTo="h-auto 2xl:h-[calc(100vh-134px)]"
      leave="transition-all duration-500"
      leaveFrom="h-auto 2xl:h-[calc(100vh-134px)]"
      leaveTo="h-0"
      className="relative 2xl:h-[calc(100vh-134px)] overflow-hidden"
    >
      <video
        ref={ref}
        className="2xl:absolute inset-0 w-full h-full bg-black"
        preload="auto"
        muted
        autoPlay
        playsInline
        onEnded={() => setShow(false)}
      >
        <source
          src={`${process.env.NEXT_PUBLIC_DRUPAL_RUNTIME_BASE_URL}${data.field_video.uri.url}`}
          type={data.field_video.filemime}
        />
      </video>
      <button
        type="button"
        className="absolute top-0 right-0 m-5 p-2 bg-white bg-opacity-70 rounded-full shadow"
        onClick={() => setShow(false)}
      >
        <XIcon className="h-8 w-8" />
      </button>
    </Transition>
  );
}
