export default function getSocialFromUrl(url) {
  if (/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(url)) {
    return "facebook";
  }

  if (/^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(url)) {
    return "instagram";
  }

  if (/^(https?:\/\/)?((w{3}\.)?)twitter\.com\/(#!\/)?[a-z0-9_]+$/i.test(url)) {
    return "twitter";
  }

  if (/^(https?:\/\/)?((\w*\.)?)linkedin\.com\/.*/i.test(url)) {
    return "linkedin";
  }

  return "other";
}
