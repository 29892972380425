import { useEffect, useReducer } from "react";
import searchReducer, { SEARCH_ACTIONS } from "reducer/searchReducer";

export default function useSearch(endpoint = "/api/search/filter") {
  const [state, dispatch] = useReducer(searchReducer, {
    status: null,
    query: "",
    results: [],
    limit: 10,
  });

  const controller = new AbortController();
  const signal = controller.signal;

  const callSearch = (query) => {
    dispatch({
      type: SEARCH_ACTIONS.SET_QUERY,
      payload: query,
    });
  };

  const cancelSearch = () => {
    controller.abort();
  };

  const resetSearch = () => {
    dispatch({
      type: SEARCH_ACTIONS.SET_RESULTS,
      payload: [],
    });
  };

  useEffect(() => {
    if (state.query === "") {
      dispatch({
        type: SEARCH_ACTIONS.SET_RESULTS,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ACTIONS.FETCH_START,
      });

      fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          params: {
            filter: {
              fulltext: state.query,
            },
            page: {
              limit: state.limit,
            },
          },
        }),
        signal: signal,
      })
        .then((response) => {
          return response.json();
        })
        .then(
          (data) => {
            dispatch({
              type: SEARCH_ACTIONS.FETCH_SUCCESS,
              payload: data,
            });
          },
          (error) => {
            dispatch({
              type: SEARCH_ACTIONS.FETCH_FAIL,
              payload:
                error instanceof TypeError
                  ? error.toString()
                  : error.statusText,
            });
          }
        );
    }

    return () => cancelSearch();
  }, [state.query]);

  return { state, callSearch, cancelSearch, resetSearch };
}
