import React from "react";

export default function IconEnvelope({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
      aria-hidden="true"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="10px"
        d="M444.34 148.01c0-1.14-.39-2.14-.65-3.2-1.48-6.12-6.74-10.75-13.31-10.75h-5.35c6.81 0 10.05 8.39 5 12.96l-107.64 97.55-36.82 33.37c-16.78 15.2-44.23 15.2-61.01 0l-36.83-33.37-107.64-97.55c-5.05-4.57-1.81-12.96 5-12.96h-5.35c-7.7 0-13.95 6.25-13.95 13.95v215.98c0 7.7 6.25 13.95 13.95 13.95h350.64c7.71 0 13.95-6.25 13.95-13.95V148.01ZM72.17 371.57l118.7-118.69-118.7 118.69Zm365.8 0L316.98 250.58l120.99 120.99Z"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="10px"
        d="m80.11 147.02 107.64 97.55 36.83 33.37c16.78 15.2 44.23 15.2 61.01 0l36.82-33.37 107.64-97.55c5.05-4.57 1.81-12.96-5-12.96H85.11c-6.81 0-10.05 8.39-5 12.96ZM437.97 371.57 316.98 250.59"
      />
    </svg>
  );
}
