import React, { createRef, useRef, useEffect, useReducer } from "react";
import { useForm as useHookForm } from "react-hook-form";
import formReducer, { FORM_ACTIONS, FORM_STATUS } from "reducer/formReducer";

export default function useForm(id, data) {
  const [state, dispatch] = useReducer(formReducer, {
    status: null,
    message: "",
    required: true,
    fields: [],
    captcha: null,
  });
  const hookForm = useHookForm();
  const formRef = createRef();
  const captchaRef = useRef();
  const { signal, abort } = new AbortController();

  useEffect(() => {
    // Transform form data (JSON to Array)
    if (data !== null) {
      const tmpFields = [];
      let tmpCaptcha = null;

      Object.keys(data).forEach((key) => {
        const type = data[key]["#type"];
        const name = data[key]["#webform_key"];

        if (type && type === "hidden" && name && name === "captcha") {
          tmpCaptcha = data[key];
        } else {
          tmpFields.push(data[key]);
        }
      });

      if (tmpFields.length > 0) {
        dispatch({
          type: FORM_ACTIONS.SET_FIELDS,
          payload: tmpFields,
        });
      }

      // Captcha are managed differently
      if (tmpCaptcha !== null) {
        dispatch({
          type: FORM_ACTIONS.SET_CAPTCHA,
          payload: tmpCaptcha,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    // Reset form field after a successfull submit
    if (state.status === FORM_STATUS.SUCCESS) {
      hookForm.reset({});
      formRef.current.reset();
    }

    // Message cleanup timeout
    if (
      state.status === FORM_STATUS.SUCCESS ||
      state.status === FORM_STATUS.ERROR
    ) {
      const timeout = setTimeout(
        () => dispatch({ type: FORM_ACTIONS.SUBMIT_RESET }),
        5000
      );
      return () => clearTimeout(timeout);
    }

    return () => null;
  }, [state.status]);

  const setRequired = (value) => {
    dispatch({ type: FORM_ACTIONS.SET_REQUIRED, payload: value });
  };

  const submit = async (submittedData) => {
    const originalData = submittedData;

    // Prevent submit spam
    if (state.status === FORM_STATUS.LOADING) {
      return undefined;
    }

    // Captcha token handling
    if (state.captcha && captchaRef.current) {
      const captchaToken = await captchaRef.current.executeAsync();

      if (captchaToken) {
        originalData[state.captcha["#webform_key"]] = captchaToken;
      }
    }

    dispatch({ type: FORM_ACTIONS.SUBMIT_START });

    const response = await fetch(`/api/forms/submit`, {
      method: "POST",
      body: JSON.stringify({
        webform_id: id,
        ...originalData,
      }),
      signal: signal,
    });

    if (response.ok) {
      dispatch({ type: FORM_ACTIONS.SUBMIT_SUCCESS });
      return true;
    }

    dispatch({ type: FORM_ACTIONS.SUBMIT_FAIL });
    return false;
  };

  return { state, hookForm, formRef, captchaRef, submit, abort, setRequired };
}
