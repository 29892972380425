import { Switch } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import useField from "lib/useField";
import React from "react";
import { Controller } from "react-hook-form";

export default function Checkboxes({ control, errors, field }) {
  const { name, disabled, required, requiredMessage, options } =
    useField(field);
  const hasError = errors[name] || false;

  if (options === null) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required && requiredMessage,
        disabled,
      }}
      render={({ field: { value, onChange } }) => (
        <>
          <div
            className={classNames(
              hasError && "text-red-500",
              "inline-block font-important font-bold mb-1"
            )}
          >
            {field["#title"]}
            {required && <span className="text-institution"> *</span>}
          </div>

          {hasError && (
            <p className="text-sm text-red-500 mb-2 font-semibold">
              {errors[name].message}
            </p>
          )}

          <div className="flex flex-col gap-2">
            {options.map((option) => {
              const isChecked = value?.includes(option.value) || false;
              const changeHandler = () => {
                if (isChecked) {
                  onChange(value.filter((v) => v !== option.value));
                } else {
                  onChange(value ? [option.value, ...value] : [option.value]);
                }
              };

              return (
                <Switch.Group>
                  <div className="flex items-center gap-4">
                    <Switch
                      checked={isChecked}
                      onChange={changeHandler}
                      className="relative inline-flex p-1 transition-colors border border-institution bg-gray-100"
                    >
                      <CheckIcon
                        className={classNames(
                          "h-4 w-4 transition-colors",
                          isChecked ? "text-institution" : "text-transparent"
                        )}
                      />
                    </Switch>

                    <Switch.Label className="cursor-pointer">
                      <span>{option.key}</span>
                    </Switch.Label>
                  </div>
                </Switch.Group>
              );
            })}
          </div>
        </>
      )}
    />
  );
}
