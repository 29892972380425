import React from "react";
import Link from "next/link";

export default function SecondaryMenu({ menu }) {
  return (
    menu && (
      <ul className="flex flex-col xl:flex-row items-center xl:items-start xl:-mx-4 xl:divide-x divide-default">
        {menu.map((item) => {
          return (
            <li key={item.id}>
              <Link href={item.url} passHref>
                <a className="block py-2 xl:px-12 font-important font-medium uppercase">
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
