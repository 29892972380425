export const SEARCH_STATUS = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};

export const SEARCH_ACTIONS = {
  FETCH_START: "fetch_start",
  FETCH_RESET: "fetch_reset",
  FETCH_SUCCESS: "fetch_success",
  FETCH_FAIL: "fetch_fail",
  SET_QUERY: "set_query",
  SET_RESULTS: "set_results",
  SET_LIMIT: "set_limit",
};

export default function searchReducer(state, action) {
  switch (action.type) {
    case SEARCH_ACTIONS.FETCH_START:
      return {
        ...state,
        status: SEARCH_STATUS.LOADING,
      };
    case SEARCH_ACTIONS.FETCH_RESET:
      return {
        ...state,
        status: null,
      };
    case SEARCH_ACTIONS.FETCH_SUCCESS:
      return {
        ...state,
        status: SEARCH_STATUS.SUCCESS,
        results: action.payload,
      };
    case SEARCH_ACTIONS.FETCH_FAIL:
      return {
        ...state,
        status: SEARCH_STATUS.ERROR,
        results: [],
      };
    case SEARCH_ACTIONS.SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case SEARCH_ACTIONS.SET_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    case SEARCH_ACTIONS.SET_LIMIT:
      return {
        ...state,
        limit: action.payload,
      };
    default:
      return state;
  }
}
