export const FORM_ACTIONS = {
  SUBMIT_START: "submit_start",
  SUBMIT_RESET: "submit_reset",
  SUBMIT_SUCCESS: "submit_success",
  SUBMIT_FAIL: "submit_fail",
  SET_MESSAGE: "set_message",
  SET_REQUIRED: "set_required",
  SET_FIELDS: "set_fields",
  SET_CAPTCHA: "set_captcha",
};

export const FORM_STATUS = {
  SUCCESS: "success",
  LOADING: "loading",
  ERROR: "error",
};

export default function formReducer(state, action) {
  switch (action.type) {
    case FORM_ACTIONS.SUBMIT_START:
      return {
        ...state,
        message: "",
        status: FORM_STATUS.LOADING,
      };
    case FORM_ACTIONS.SUBMIT_RESET:
      return {
        ...state,
        message: "",
      };
    case FORM_ACTIONS.SUBMIT_SUCCESS:
      return {
        ...state,
        message:
          "Votre message a été transmis à notre équipe. <br>Il sera traité dans les plus brefs délais.",
        status: FORM_STATUS.SUCCESS,
      };
    case FORM_ACTIONS.SUBMIT_FAIL:
      return {
        ...state,
        message:
          "Une erreur est survenue lors de la soumission du formulaire. <br>Merci de contacter un administrateur ou de réessayer plus tard.",
        status: FORM_STATUS.ERROR,
      };
    case FORM_ACTIONS.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case FORM_ACTIONS.SET_REQUIRED:
      return {
        ...state,
        required: action.payload,
      };
    case FORM_ACTIONS.SET_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };
    case FORM_ACTIONS.SET_CAPTCHA:
      return {
        ...state,
        captcha: action.payload,
      };
    default:
      return state;
  }
}
