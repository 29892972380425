import React from "react";
import Text from "components/Form/Fields/Text";
import Textarea from "components/Form/Fields/Textarea";
import Select from "components/Form/Fields/Select";
import Checkbox from "components/Form/Fields/Checkbox";
import Checkboxes from "components/Form/Fields/Checkboxes";
import Radios from "components/Form/Fields/Radios";
import File from "components/Form/Fields/File";
import Captcha from "components/Form/Fields/Captcha";
import Button from "components/Button";
import Body from "components/Body";
import useForm from "hook/useForm";
import classNames from "lib/classNames";
import { ArrowRightIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";

export default function Form({ id, data, variant = "institution" }) {
  const { t } = useTranslation("common");
  const { state, hookForm, formRef, captchaRef, submit, setRequired } = useForm(
    id,
    data
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = hookForm;

  return (
    <form ref={formRef} onSubmit={handleSubmit(submit)} className="relative">
      {state.required && <div className="mb-8">{t("form.required")}</div>}

      <FormFields
        fields={state.fields}
        setRequired={setRequired}
        register={register}
        control={control}
        errors={errors}
        variant={variant}
      />

      {state.captcha && <Captcha ref={captchaRef} field={state.captcha} />}

      {state.message !== "" && (
        <div
          className={classNames(
            state.status === "success" && "bg-green-500",
            state.status === "error" && "bg-red-500",
            "prose max-w-none mt-8 md:mt-0 p-4 text-white rounded-md"
          )}
        >
          <Body value={state.message} />
        </div>
      )}

      <div className="xl:absolute right-0 top-full">
        <Button
          type="submit"
          theme="white"
          disabled={state.status === "submitting"}
        >
          <span className="flex items-center gap-2">
            <span>Envoyer</span>
            {state.status === "submitting" ? (
              <svg
                className="animate-spin h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden={state.status !== "submitting"}
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <ArrowRightIcon className="h-6 w-5" />
            )}
          </span>
        </Button>
      </div>
    </form>
  );
}

function FormFields({
  fields,
  register,
  control,
  errors,
  setRequired,
  variant,
}) {
  const halfIndex = Math.ceil(fields.length / 2);
  const firstHalf = fields.filter((field, index) => index < halfIndex);
  const secondHalf = fields.filter((field, index) => index >= halfIndex);

  const components = {
    textfield: Text,
    email: Text,
    tel: Text,
    textarea: Textarea,
    select: Select,
    checkbox: Checkbox,
    checkboxes: Checkboxes,
    radios: Radios,
    managed_file: File,
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 xl:gap-8">
      {firstHalf.length > 0 && (
        <div>
          {firstHalf.map((field) => {
            const type = field["#type"];
            if (Object.prototype.hasOwnProperty.call(components, type)) {
              const FormField = components[type];
              return (
                <div key={field.id} className="mb-6">
                  <FormField
                    register={register}
                    control={control}
                    errors={errors}
                    field={field}
                    requiredHandler={setRequired}
                    variant={variant}
                  />
                </div>
              );
            }

            console.warn(`Unhandled form field type "${type}".`);
            return undefined;
          })}
        </div>
      )}
      {secondHalf.length > 0 && (
        <div key={`fields-left-${secondHalf.length}`}>
          {secondHalf.map((field) => {
            const type = field["#type"];
            if (Object.prototype.hasOwnProperty.call(components, type)) {
              const FormField = components[type];
              return (
                <div key={field.id} className="mb-6">
                  <FormField
                    register={register}
                    control={control}
                    errors={errors}
                    field={field}
                    requiredHandler={setRequired}
                    variant={variant}
                  />
                </div>
              );
            }

            console.warn(`Unhandled form field type "${type}".`);
            return undefined;
          })}
        </div>
      )}
    </div>
  );
}
