import React from "react";

export default function IconSearch({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
      aria-hidden="true"
    >
      <circle
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="10px"
        cx="224.74"
        cy="194.35"
        r="165.28"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="10px"
        d="M102.85 179.13c2.35-16.9 8-33.18 16.62-47.91A128.23 128.23 0 0 1 151.6 94.7a124.448 124.448 0 0 1 43.07-21.61"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeWidth="10px"
        d="m288.5 348.68 88.42 117.35c13.83 18.6 40.12 22.46 58.71 8.62 18.6-13.83 22.46-40.12 8.62-58.71-.11-.14-.21-.28-.32-.42l-88.42-117.34"
      />
    </svg>
  );
}
