import React from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import IconFacebook from "components/Icons/IconFacebook";
import IconInstagram from "components/Icons/IconInstagram";
import IconTwitter from "components/Icons/IconTwitter";
import IconLinkedIn from "components/Icons/IconLinkedIn";
import getSocialFromUrl from "lib/getSocialFromUrl";
import classNames from "lib/classNames";
import Link from "next/link";

export default function SocialNetworksMenu({ menu, small = false }) {
  const components = {
    facebook: IconFacebook,
    instagram: IconInstagram,
    twitter: IconTwitter,
    linkedin: IconLinkedIn,
    other: ExternalLinkIcon,
  };

  return (
    <ul className="flex items-center justify-center gap-8">
      {menu.map((link) => {
        const social = getSocialFromUrl(link.url);
        const IconComponent = components[social];

        return (
          <li key={link.title}>
            <Link href={link.url} passHref>
              <a
                className="flex items-center justify-center"
                target={link.options.attributes.target}
              >
                <IconComponent
                  className={classNames(
                    "inline-block",
                    small ? "h-6 w-6" : "w-8 h-8"
                  )}
                />
                <span
                  className={classNames(
                    social !== "other" ? "sr-only" : "ml-2"
                  )}
                >
                  {link.title}
                </span>
              </a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
