import React, { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import SearchResults from "components/Search/SearchResults";
import useSearch from "hook/useSearch";
import IconSearch from "components/Icons/IconSearch";

export default function SearchForm() {
  const { t } = useTranslation("common");
  const [selected, setSelected] = useState(null);
  const { state, callSearch, cancelSearch, resetSearch } = useSearch();
  const router = useRouter();

  useEffect(() => {
    if (selected !== null) {
      cancelSearch();
      resetSearch();
      setSelected(null);
      router.push(selected);
    }
  }, [selected]);

  return (
    <Combobox value={null} onChange={setSelected}>
      <div className="relative">
        <div className="relative bg-white rounded-full overflow-hidden">
          <span className="absolute right-0 inset-y-0 flex items-center justify-center px-6 py-4 bg-default text-white rounded-full">
            <IconSearch className="h-5 w-5" aria-hidden="true" />
          </span>
          <Combobox.Input
            onChange={(event) => callSearch(event.target.value)}
            placeholder={t("search.placeholder")}
            className="relative w-full px-6 py-4 appearance-none bg-transparent focus:outline-none font-important font-semibold placeholder:text-default placeholder:uppercase"
            autoComplete="off"
          />
        </div>

        <div className="absolute bottom-full mb-4 w-full z-30 mt-4 px-6 bg-white shadow-spread rounded-3xl">
          <SearchResults results={state.results} />
        </div>
      </div>
    </Combobox>
  );
}
