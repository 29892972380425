import classNames from "lib/classNames";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Form from "./Form";
import SocialNetworksMenu from "./Menu/SocialNetworksMenu";

export default function Memento({ form, menu, variant = "institution" }) {
  const { t } = useTranslation("common");

  const themes = {
    default: {
      text: "text-default",
      text_alt: "text-white",
      background: "bg-default",
      decoration: "bg-white",
    },
    primary: {
      text: "text-primary",
      text_alt: "text-white",
      background: "bg-primary",
      decoration: "bg-white",
    },
    institution: {
      text: "text-institution",
      text_alt: "text-white",
      background: "bg-institution",
      decoration: "bg-white",
    },
    formation: {
      text: "text-formation",
      text_alt: "text-default-dark",
      background: "bg-formation",
      decoration: "bg-default-dark",
    },
    internship: {
      text: "text-internship",
      text_alt: "text-white",
      background: "bg-internship",
      decoration: "bg-white",
    },
    news: {
      text: "text-news",
      text_alt: "text-white",
      background: "bg-news",
      decoration: "bg-white",
    },
    inscription: {
      text: "text-inscription",
      text_alt: "text-white",
      background: "bg-inscription",
      decoration: "bg-white",
    },
  };

  return (
    <section className={classNames("relative", themes[variant].background)}>
      <div className="absolute top-0 inset-x-0 bg-white h-1/2">
        <Image
          src="/images/background-memento.jpg"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          className="opacity-50"
        />
      </div>
      <div
        className="hidden xl:block absolute top-1/2 inset-x-0 h-40 bg-squares-pattern bg-contain bg-repeat-x opacity-20 -translate-y-1/2"
        aria-hidden="true"
      />
      <div className="relative container py-16">
        <div className="px-4 xl:px-10 py-10 bg-white mb-16">
          <h2
            className={classNames(
              "mb-8 xl:mb-10 font-important font-bold text-2xl xl:text-3xl xl:text-center uppercase",
              themes[variant].text
            )}
          >
            {t("form.contact.title")}
          </h2>
          {form && <Form id={form.id} data={form.data} variant={variant} />}
        </div>
        {menu && (
          <div
            className={classNames(
              "max-w-3xl mx-auto",
              themes[variant].text_alt
            )}
          >
            <h2 className="relative mb-5 font-important font-bold text-lg text-center uppercase">
              <span
                className={classNames(
                  "absolute top-1/2 inset-x-0 h-0.5",
                  themes[variant].decoration
                )}
                aria-hidden="true"
              />
              <span
                className={classNames(
                  "relative inline-block px-2",
                  themes[variant].background
                )}
              >
                {t("socialnetworks.title")}
              </span>
            </h2>

            <SocialNetworksMenu menu={menu} />
          </div>
        )}
      </div>
    </section>
  );
}
